import React from "react"
import { Link } from "gatsby"

import Layout from "../layouts/layout.dk"
import Image from "../components/image"
import SEO from "../components/seo"

import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro"
import EmotionHeader, { Text, Content } from "../components/common"


import { useTopicImage } from "../components/hooks/useTopicImage"

const IndexPage = props => {
  const topicImage = useTopicImage()
  const backgroundFluidImageStack = [
    topicImage.childImageSharp.fluid,
    `linear-gradient(rgba(17, 17, 15, 0.33), rgba(47, 17, 17, 0.53))`,
  ].reverse()

  const pageData = {
    pageTitle: "Menu",
    subTitle: "Vi elsker kage...",
    sectionTitle: "Vi elsker kage......",
    seoTitle: "Menu: kage, cafe, morgenmad hele dagen",
  }

  const formConfig = {
    id: "tina-tutorial-index",
    label: "Edit Page",
    fields: [
      {
        name: "pageTitle",
        label: "Page Title",
        component: "text",
      },
      {
        name: "subTitle",
        label: "Subtitle",
        component: "text",
      },
      {
        name: "seoTitle",
        label: "SEO Title",
        component: "text",
      },
    ],
    initialValues: pageData,
    onSubmit: async () => {
      window.alert("Saved!")
    },
  }

  const GalleryImage = styled.div`
    ${props =>
      css`
        background-image: url("${props.imageSrc}");
      `}
    ${tw`h-full w-full bg-cover rounded-sm shadow-md`}
  `

  return (
    <Layout
      location={props.location}
      showHeader={false}
      imageData={backgroundFluidImageStack}
      title={pageData.pageTitle}
    >
      <SEO title={pageData.sectionTitle} />

      <Content>
          <EmotionHeader
            title="Vores menukort"
            subTitle="er i øjeblikket under revision"
          />
          <Text>
            <div>
Tak fordi du kigger forbi! Vi vil gerne gøre opmærksom på, at vi i øjeblikket er ved at opdatere vores menukort.



</div>
        <div>
  Du kan dog stadig nyde vores hjemmelavede kager og tærter, samt friskbagte vafler og pandekager.
  
  Hvis du foretrækker mere hjertelige retter, tilbyder vi et udvalg af flamme pandekager, La Flute og vafler med laks.
  
  Derudover serverer vi en lækker og hjertelig morgenmad. 
  

            </div>

        <div>


 Hos os kan du også vælge mellem forskellige kaffesorter og te eller køle af med forfriskende kolde drikkevarer. Vi håber, at du vil føle dig godt tilpas hos os og lade dig overbevise af vores tilbud.

          </div>


      </Text>
    </Content>
    </Layout>
  )
}

export default IndexPage
